
<!-- TODO: if admin, show all projects (with indicators) -->
<!-- TODO: fix projects not showing up in inactive events -->
<!-- TODO: rename everything from hackathon to events -->
<!-- TODO: account for team lead in "join team" logic -->
<template>
    <div id="app">
        <NavHeader v-if="authorizedRoute" />

        <div v-if="loading" class="loading-spinner centered">
            <DsSpinner />
        </div>

        <main>
            <router-view />
        </main>
    </div>
</template>

<script>
import NavHeader from '@/components/NavHeader';
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
        NavHeader,
    },

    data() {
        return {
            loading: false,
        };
    },

    mounted() {
        if (this.user.uid) this.load();
    },

    watch: {
        // eslint-disable-next-line
        ['user.uid'](value) {
            if (value) this.load();
        },
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            guests: ({ users }) => users.guests,
        }),

        ...mapGetters({
            isGuest: 'users/isGuest',
        }),

        authorizedRoute() {
            return !['login', 'logout', 'forbidden'].includes(this.$route.name);
        },
    },

    methods: {
        async load() {
            this.loading = true;

            try {
                await Promise.all([
                    this.$store.dispatch('users/LOAD_USERS'),
                    this.$store.dispatch('projects/LOAD_PROJECTS'),
                    this.$store.dispatch('hackathons/LOAD_HACKATHONS'),
                    this.$store.dispatch('hackathons/LOAD_HACKATHON_DEMO'),
                    this.$store.dispatch('hackathons/LOAD_HACKATHON_COMMENTS'),
                    this.$store.dispatch('tags/LOAD_TAGS'),
                ]);

                this.loading = false;
            } catch (error) {
                this.loading = false;

                if (this.$route.name !== 'login') this.$router.replace({ name: 'login' });
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss">
body {
    background-color: $color-gray-200;
}
</style>

<style lang="scss" scoped>
#app {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 1280px;
    max-width: 100%;
}

main {
    padding: 0 $spacing-200 $spacing-200;
}
</style>
