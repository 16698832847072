import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import interceptor from './interceptor';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({ mode: 'history', routes });

router.beforeResolve((to, from, next) => {
    const requestedPathName = to.name;

    setTitle(Vue.prototype.$i18nInstance.t(to.meta.title || 'PD Events | Keap'));

    if (requestedPathName === 'forbidden') {
        firebase.auth().signOut().then(() => {
            store.commit('auth/SET_USER', { email: '' });
            store.commit('auth/SET_GOOGLE_TOKEN', '');
            next();
        }).catch((error) => {
            // eslint-disable-next-line
            console.error(error);
        });
    } else {
        next();
    }
});

const setTitle = (title) => {
    Vue.nextTick(() => {
        document.title = title;
    });
};

interceptor.setup(router);

router.beforeEach((to, from, next) => {
    const { currentUser } = firebase.auth();

    const user = currentUser || store.state?.auth?.user?.uid;

    if (!to?.meta?.public && !user) {
        return next('login');
    }

    return next();
});

export default router;
